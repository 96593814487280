import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/icon/phone-call.png";
import img2 from "../../assets/img/icon/mailing.png";
import logo from "../../assets/img/logo/logo.png";
import { FaTiktok } from "react-icons/fa6";

function Main() {
  const [Btnshow, setBtnshow] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Inline styles for icons
  const iconStyle = {
    fontSize: "18px",
    color: "#ffffff",
    margin: "0 10px",
    transition: "transform 0.2s ease-in-out",
    display: "inline-block",
  };

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 d-none d-lg-block">
                <div className="header-social">
                  <span>
                    Follow us:-
                    <Link
                      to="https://www.facebook.com/profile.php?id=61568666652370"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Facebook"
                      style={iconStyle}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <i className="fab fa-facebook-f" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/getknowledgeacademy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                      style={iconStyle}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <i className="fab fa-instagram" />
                    </Link>
                    <Link
                      to="https://www.tiktok.com/@getknowledgeacade?_t=8rVkbZjSrVI&_r=1"
                      title="TikTok"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={iconStyle}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <FaTiktok />
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                <div className="header-cta">
                  <ul>
                    <li>
                      <div
                        className="call-box"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="icon" style={{ marginRight: "10px" }}>
                          <img src={img1} alt="Phone icon" />
                        </div>
                        <div
                          className="text"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <strong>
                            <Link to="tel:+15874443800">+1 587 444 3800</Link>
                          </strong>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className="call-box"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="icon" style={{ marginRight: "10px" }}>
                          <img src={img2} alt="Mail icon" />
                        </div>
                        <div
                          className="text"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <strong>
                            <Link to="mailto:info@getknowledgeacademy.com">
                              info@getknowledgeacademy.com
                            </Link>
                          </strong>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="header-sticky"
          className={`menu-area${isScrolled ? " sticky-menu" : ""}`}
        >
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">Get Knowledge Academy</Link>
                        </li>
                        <li>
                          <Link to="/courses">Programs</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block">
                  <div className="login">
                    <ul>
                      <li>
                        <div className="second-header-btn">
                          <Link to="/contact" className="btn">
                            Contact Us
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <a
                        href="#nav"
                        onClick={() => setmobile(!mobile)}
                        className={`meanmenu-reveal ${
                          mobile ? "meanclose" : ""
                        }`}
                        style={{
                          right: 0,
                          left: "auto",
                          textAlign: "center",
                          fontSize: 18,
                        }}
                      >
                        {mobile ? "X" : <span>☰</span>}
                      </a>

                      {mobile && (
                        <nav className="mean-nav">
                          <ul style={{ display: "block" }}>
                            <li>
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <Link to="/about">Get Knowledge Academy</Link>
                            </li>
                            <li>
                              <Link to="/courses">Programs</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {Btnshow && (
        <>
          <div className={`offcanvas-menu ${"active"}`}>
            <span className="menu-close" onClick={() => setBtnshow(false)}>
              <i className="fas fa-times" />
            </span>
            <form className="searchform" action="/">
              <input type="text" placeholder="Search" />
              <button>
                <i className="fa fa-search" />
              </button>
            </form>
          </div>
          <div className={`offcanvas-overly ${"active"}`} />
        </>
      )}
    </>
  );
}

export default Main;
