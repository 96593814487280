import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/f_logo.png";
import { FaTiktok } from "react-icons/fa6";

function Main() {
  return (
    <>
      <footer
        className="footer-bg footer-p pt-90"
        style={{
          backgroundColor: "#125875",
          backgroundImage: "url(assets/img/bg/footer-bg.png)",
        }}
      >
        <div className="footer-top pb-70">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <img src={logo} alt="" style={{ marginBottom: "0.4rem" }} />
                  <div className="f-contact">
                    <p>
                      Get knowledge Academy is committed to shaping the future
                      of education through dynamic learning experiences.
                    </p>
                  </div>
                  <div className="footer-social mt-10">
                    <Link
                      to="https://www.facebook.com/profile.php?id=61568666652370"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/getknowledgeacademy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </Link>
                    <Link
                      to="https://www.tiktok.com/@getknowledgeacade?_t=8rVkbZjSrVI&_r=1"
                      title="TikTok"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTiktok />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Our Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about"> Get Knowledge Academy</Link>
                      </li>
                      <li>
                        <Link to="/courses">Programs</Link>
                      </li>
                      <li>
                        <Link to="/contact"> Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone" />
                        <span>
                          <Link to="tel:+15874443800"> +1 (587) 444 3800</Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope" />
                        <span>
                          <Link to="mailto:info@getknowledgeacademy.com">
                            info@getknowledgeacademy.com
                          </Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check" />
                        <span>
                          Unit 240 10960 42 St NE,
                          <br /> Calgary, AB T3N 2B8
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
